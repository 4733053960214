import React from 'react'

export const MainGraphic = () => {
    return (
        <div>
            <svg height="100%" style={{ 
                fillRule: 'nonzero', 
                clipRule: 'evenodd', 
                strokeLinecap: 'round', 
                strokeLineJoin: 'round',
                filter: 'drop-shadow(0 3mm 3mm #99764e)',
                maxWidth: '60%' }} xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="100%" xmlnsvectornator="http://vectornator.io" version="1.1" viewBox="0 0 1024 1024">
            <metadata>
                <vectornatorSetting key="DimensionsVisible" value="1"/>
                <vectornatorSetting key="PencilOnly" value="0"/>
                <vectornatorSetting key="SnapToPoints" value="0"/>
                <vectornatorSetting key="OutlineMode" value="0"/>
                <vectornatorSetting key="CMYKEnabledKey" value="0"/>
                <vectornatorSetting key="RulersVisible" value="1"/>
                <vectornatorSetting key="SnapToEdges" value="0"/>
                <vectornatorSetting key="GuidesVisible" value="1"/>
                <vectornatorSetting key="DisplayWhiteBackground" value="0"/>
                <vectornatorSetting key="doHistoryDisabled" value="0"/>
                <vectornatorSetting key="SnapToGuides" value="1"/>
                <vectornatorSetting key="TimeLapseWatermarkDisabled" value="0"/>
                <vectornatorSetting key="Units" value="Pixels"/>
                <vectornatorSetting key="DynamicGuides" value="0"/>
                <vectornatorSetting key="IsolateActiveLayer" value="0"/>
                <vectornatorSetting key="SnapToGrid" value="0"/>
                </metadata>
                <defs/>
                <g id="Layer 1" vectornatorlayername="Layer 1">
                <path d="M90.2535+161.576C90.2535+148.152+115.841+111.972+129.915+111.695C141.272+110.991+161.992+155.92+162.911+171.411C163.579+182.679+140.161+228.566+140.161+228.566L179.078+234.461L184.348+757.488L139.576+759.867C139.576+759.867+159.813+803.57+159.14+817.762C158.474+831.814+139.207+874.893+129.185+874.893C115.822+874.893+93.3838+831.724+94.0505+816.969C94.7172+802.213+117.754+756.695+117.754+756.695L71.0998+750.747L75.9423+223.273L115.273+224.44C115.273+224.44+90.2535+175.66+90.2535+161.576Z" fillOpacity="0.989726" fill="#7c4308" opacity="1"/>
                <path d="M844.253+152.576C844.253+139.152+869.841+102.972+883.915+102.695C895.272+101.991+915.992+146.92+916.911+162.411C917.579+173.679+894.161+219.566+894.161+219.566L933.078+225.461L938.348+748.488L893.576+750.867C893.576+750.867+913.813+794.57+913.14+808.762C912.474+822.814+893.207+865.893+883.185+865.893C869.822+865.893+847.384+822.724+848.05+807.969C848.717+793.213+871.754+747.695+871.754+747.695L825.1+741.747L829.942+214.273L869.273+215.44C869.273+215.44+844.253+166.66+844.253+152.576Z" fillOpacity="0.989726" fill="#7c4308" opacity="1"/>
                <path d="M41.9636+241.494C54.2992+229.909+141.313+251.52+159.798+253.399C203.736+257.863+788.706+249.937+805.853+245.869C825.7+241.159+955.979+220.712+968.949+232.9C988.59+251.357+970.642+665.524+957.927+691.666C945.212+717.808+886.307+730.904+886.307+730.904L102.483+732.434C102.483+732.434+67.471+729.633+47.0283+699.431C26.5855+669.23+14.5022+267.287+41.9636+241.494Z" opacity="1" fill="#fffaed"/>
                <path d="M631.837+188.817L633.043+188.817L633.043+190.023L631.837+190.023L631.837+188.817Z" opacity="1" fill="#fffaed"/>
                <path d="M146.869+353.552C151.814+349.702+167.168+342.593+167.168+342.593C167.168+342.593+168.667+359.886+165.607+371.916C162.546+383.946+148.719+395.299+148.719+395.299L149.732+442.112C149.732+442.112+156.931+429.575+168.729+421.083C180.528+412.591+191.631+412.197+191.631+412.197C191.631+412.197+192.698+429.833+179.66+442.112C166.621+454.392+150.773+452.479+150.773+452.479L149.454+473.504L150.301+490.206L154.307+514.488C154.307+514.488+158.84+507.893+168.209+501.673C177.578+495.453+191.891+488.641+191.891+488.641C191.891+488.641+193.713+516.186+180.961+521.517C168.209+526.849+155.371+525.697+155.371+525.697C155.371+525.697+154.761+530.396+155.904+541.148C157.047+551.901+155.186+560.824+155.186+560.824L157.447+582.622C157.447+582.622+160.556+598.859+151.775+606.185C143.669+612.949+140.363+593.195+140.363+593.195L140.103+573.323L139.322+548.147C139.322+548.147+123.071+556.592+110.98+550.011C98.8896+543.43+100.729+523.986+100.729+523.986C100.729+523.986+127.276+525.781+132.533+531.464C140.289+539.851+140.103+541.039+140.103+541.039L140.623+526.229L139.607+507.077C139.607+507.077+120.845+514.409+110.175+508.189C101.139+502.922+98.4638+479.459+98.4638+479.459C98.4638+479.459+126.83+482.717+132.035+489.233C139.528+498.613+139.322+498.415+139.322+498.415L139.006+466.704C139.006+466.704+124.488+459.91+112.777+456.06C101.066+452.209+94.8204+430.292+94.8204+430.292C94.8204+430.292+108.874+429.107+119.283+434.142C129.693+439.177+140.318+456.546+140.318+456.546L140.581+438.023L141.664+422.564L141.369+407.25L140.843+392.909C140.843+392.909+138.712+380.868+137.761+374.878C136.809+368.887+141.41+357.803+146.869+353.552Z" opacity="1" fill="#436a30"/>
                <path d="M872.73+348.303C867.793+344.452+852.464+337.344+852.464+337.344C852.464+337.344+850.968+354.636+854.023+366.666C857.079+378.696+870.883+390.049+870.883+390.049L869.872+436.862C869.872+436.862+862.684+424.324+850.905+415.832C839.127+407.34+828.042+406.947+828.042+406.947C828.042+406.947+826.977+424.582+839.993+436.862C853.01+449.141+868.832+447.228+868.832+447.228L870.149+468.253L869.304+484.954L865.304+509.236C865.304+509.236+860.778+502.641+851.425+496.421C842.072+490.202+827.782+483.389+827.782+483.389C827.782+483.389+825.963+510.935+838.694+516.266C851.425+521.597+864.241+520.446+864.241+520.446C864.241+520.446+864.851+525.144+863.71+535.897C862.569+546.649+864.426+555.572+864.426+555.572L862.169+577.369C862.169+577.369+859.066+593.607+867.832+600.933C875.924+607.697+879.225+587.942+879.225+587.942L879.485+568.071L880.264+542.895C880.264+542.895+896.489+551.34+908.559+544.759C920.63+538.178+918.793+518.734+918.793+518.734C918.793+518.734+892.29+520.529+887.042+526.213C879.299+534.599+879.485+535.787+879.485+535.787L878.965+520.978L879.979+501.826C879.979+501.826+898.711+509.157+909.363+502.938C918.384+497.67+921.055+474.208+921.055+474.208C921.055+474.208+892.735+477.466+887.539+483.982C880.059+493.362+880.264+493.163+880.264+493.163L880.58+461.454C880.58+461.454+895.074+454.66+906.765+450.809C918.457+446.959+924.692+425.041+924.692+425.041C924.692+425.041+910.662+423.856+900.27+428.892C889.877+433.927+879.269+451.296+879.269+451.296L879.007+432.772L877.926+417.313L878.221+402L878.745+387.659C878.745+387.659+880.874+375.618+881.823+369.628C882.773+363.637+878.18+352.554+872.73+348.303Z" opacity="1" fill="#436a30"/>
                </g>
            </svg>

        </div>
    )
}
