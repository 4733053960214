import React from 'react'
import { FOOTER_STYLES } from '../constants/styles'

export const Footer = () => {
    return (
        <div style={FOOTER_STYLES}>
            <p>developed by austin wills</p>
        </div>
    )
}
